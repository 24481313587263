import React from "react";
import { connect } from "react-redux";
import { login } from "./../store/actions";
import Logo from "./../components/login/Logo";
import Form from "./../components/login/Form";

const LoginPage = ({login, loginValue}) => {
    console.log(loginValue, login);
    return (
      <div id="Login" className="d-flex">
        <div className="text-white title d-flex align-items-center justify-content-center">
          <div className="text-center">Shoot the incident from your smart phone <br/> upload them to the App and get rewarded.</div>
        </div>
        <div className="form">
          <div className="form-container">
            <Logo />
            <Form login={login} />
          </div>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => ({ loginValue: state.login })

const mapDispatchToProps = (dispatch) => {
    return {
      login: (payload) => dispatch(login(payload)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);