import React from "react";
import HeaderBG from "./../../images/landing-page/header-bg.png";
import { Link } from "react-router-dom";


const Header = () => {
    return (
        <div className="landing-page-header">
            <div className="text">
                <div className="text-large">
                    Experience <br/>
                    <span className="blue">real news</span> with <br/>
                    FlapLive
                </div>
                <div className="text-small">
                    We bring real stories to you!
                </div>
                <div className="buttons">
                    <Link className="explore" to="/about">EXPLORE</Link>
                </div>
            </div>
           <div className="header-img">
                <img src={HeaderBG} alt="Header Background" />
            </div> 
        </div>
    )
}

export default Header;