import React from "react";
import channelIcon from "./../../images/landing-page/channel-icon.svg";
import flapperCoins from "./../../images/landing-page/flapper-coins.svg";
import featuresBackground from "./../../images/landing-page/features.png";
import searchIcon from "./../../images/landing-page/search-icon.svg";
import groupLive from "./../../images/landing-page/group-live.svg";
const Features = () => {
    return (
        <div className="features">
            <div className="intro">
                <div className="title text-center big-title">
                    <div className="black">
                        KEY FEATURES
                    </div>
                </div>
            </div>
            <div className="features-container">
                <div className="bg">
                    <img src={featuresBackground} className="w-100" alt="Background"/>
                </div>

                <div className="feature feature-1">
                    <div className="title">FLAPPER COINS</div>
                    <div className="text">Every registered user is a flapper and can get a chance to earn money by recording & sharing viral footage.</div>
                    <img src={flapperCoins} alt="Flapper Icon"/>
                </div>

                <div className="feature feature-2">
                    <div className="title">CHANNEL MANAGEMENT</div>
                    <div className="text">Users can manage their channels and become renowned Flappers in the News Media Stage.</div>
                    <img src={channelIcon} alt="Channel Icon"/>
                </div>

                <div className="feature feature-3">
                    <img src={searchIcon} alt="Advanced Search Icon"/>
                    <div className="title">ADVANCE SEARCH</div>
                    <div className="text">Flap News is classified via location-based networks, giving users an opportunity to search for location-wise news.</div>
                </div>

                <div className="feature feature-4">
                    <img src={groupLive} alt="Group Live Icon"/>
                    <div className="clear"></div>
                    <div className="title">GROUP LIVE</div>
                    <div className="text">Flaplive allows the general public to have one-on-one conversations with public figures with hosts having provision to add or remove participants instantly.</div>
                </div>
            </div>
        </div>
    )
}

export default Features;