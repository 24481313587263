import React, { useEffect } from "react";
import Content from "./Content";
import ScrollTop from "./global/ScrollTop";

function App() {
  const DEFAULT_WIDTH = 1920;
  const DEFAULT_HEIGHT = 1080;
  const MAX_DESKTOP_WIDTH = 1250;
  const resizeHandler = () => {
    let head = document.head || document.getElementsByTagName("head")[0];
    let style = document.createElement("style");
    style.setAttribute("id", "styleZoom");
    style.type = "text/css";
    let height =
      window.innerHeight / DEFAULT_HEIGHT < 0.7
        ? 0.7
        : window.innerHeight / DEFAULT_HEIGHT;
    let width =
      window.innerWidth / DEFAULT_WIDTH < 0.7
        ? 0.7
        : window.innerWidth / DEFAULT_WIDTH;

    let v = height < width ? height : width;

    if (MAX_DESKTOP_WIDTH < window.innerWidth) {
      style.appendChild(
        document.createTextNode(
          `:root{ --zoom: ${
            v < 1 ? (MAX_DESKTOP_WIDTH >= window.innerWidth ? 1 : v) : 1
          } }`
        )
      );

      let zoom = document.getElementById("styleZoom");
      if (zoom) {
        zoom.remove();
      }

      head.appendChild(style);
    } else {
      style.appendChild(document.createTextNode(`:root{ --zoom: ${0.7} }`));

      let zoom = document.getElementById("styleZoom");
      if (zoom) {
        zoom.remove();
      }

      head.appendChild(style);
    }
  };
  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
  });
  return (
    <div className="App">
      <ScrollTop />
      <Content></Content>
    </div>
  );
}

export default App;
