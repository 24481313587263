import React from "react";

const Header = () => {
    return (
        <div className="terms-page-header container-fluid">
            <div className="text">
                <div className="title text-center">
                    FlapLive Privacy Statement 
                </div>
                <div className="text-box text-black text-left">
                    FlapLive is the slightest requesting way to deal with start or watch a live video impact. FlapLive is one of the Services which depicts how we treat your information when you use our things and organizations. The going with portrays sharpens that are specific to FlapLive.                 
                </div>
            </div>
        </div>
    )
}

export default Header;