import React from "react";
import playStore from "./../images/play-store.svg";
import playStoreDark from "./../images/google-play.svg";
import  {PLAY_STORE_LINK} from '../constants/index';
const GooglePlay = ({mode, size}) => {
    if(!mode){
        mode = "light"
    }

    if(!size){
        size = "large"
    }

    return (
            mode === "light" ? (
                <a className={`google-play ${mode} ${size}`} href={PLAY_STORE_LINK}>
                    <div className="icon">
                        <img src={playStore} alt="Google Play Store"/>
                    </div>
                    <div className="text text-left">
                        <div className="upper text-left">
                            Available on the
                        </div>
                        <div className="down text-left">
                            Google Play
                        </div>
                    </div>
                </a>
            ) : (
                <a className={`google-play ${mode} ${size}`} href={PLAY_STORE_LINK}>
                    <div className="icon">
                        <img src={playStoreDark} alt="Google Play Store"/>
                    </div>
                    <div className="text text-left">
                        <div className="upper text-left">
                            GET IT ON
                        </div>
                        <div className="down text-left">
                            Google Play
                        </div>
                    </div>
                </a>
            )

    )
}

export default GooglePlay;