import React from "react";
import cloudIcon from "./../../images/landing-page/cloud-based-icon.svg";
import userManagementIcon from "./../../images/landing-page/user-management-icon.svg"
import locationIcon from "./../../images/landing-page/location-icon.svg";
import streamIcon from "./../../images/landing-page/stream-icon.svg";
import sepIcon from "./../../images/landing-page/separate-background.svg";

const News = () => {
    const newsBoxes = [
        {
            title: "Cloud -Based",
            icon: cloudIcon
        },
        {
            title: (<>User <br/> Management</>),
            icon: userManagementIcon
        },
        {
            title: "Location- Based User Management",
            icon: locationIcon
        },
        {
            title: (<> HD Quality <br />  Live Stream </>),
            icon: streamIcon
        }
    ];
    return (
        <div className="news">
            <div className="container-fluid">
                <div className="intro">
                    <div className="title big-title text-center">
                        <span className="black">THE </span>
                        <span className="blue">REAL NEWS </span>
                        <span className="black">APP</span>
                    </div>
                    <div className="text black text-center">
                        We bring to you untouched raw news footage live from all parts
                        of the world. Real news on your fingertips.
                    </div>
                </div>
                
                <div className="news-boxes d-flex justify-content-between">
                    {
                        newsBoxes.map((item, key) => (
                            <div className="box text-center" key={key}>
                                <img src={sepIcon} alt="sep-icon" className="sep-icon" />
                                <div className="data-container">
                                    <img src={item.icon} alt={item.title} />
                                    <div className="title black">{item.title}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default News;