import axios from "axios";
import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Popup from "./../../global/Popup";
import Spinner from "./../../global/Spinner";
import Call from "./../../images/contact/call.svg";
import Mail from "./../../images/contact/mail.svg";
import Location from "./../../images/contact/location.svg";
import  {CONTACTUS_APIS} from '../../constants/index'

const Header = ({API}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);    
    const [contactusTypes, setContactusTypes] = useState([]);

    const getContactusTypes = () => {
		axios.get(API.contact_us_base + API.contactusTypes).then(r => {	
            if(r.data?.contactus){
                setContactusTypes(r.data.contactus);
            }	
		}).catch(e => {
			console.log(e);
		});
    }

    useEffect(() => {
        getContactusTypes();
    }, []);

    const buildFormData = (formElement) =>{
        return new FormData(formElement);
    }

    const submit = async (e) => {
        e.preventDefault();
        setShowSpinner(true);
       let data = buildFormData(e.target);
        await axios.post(CONTACTUS_APIS.base + CONTACTUS_APIS.contact_us_insert, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(r => {
            setShowPopup(true);
            console.log(r);
        }).catch(e => {
            setShowPopup(true);
            console.log(e);
        });
        setShowSpinner(false);
    }

    return (
        <div className="container-fluid" id="ContactUs">
            {showPopup && <Popup title="Thank you!" subtitle="We've received your message and we'll get back to you within 48 hours." 
                text="Have you checked our blog yet?" showPopup={setShowPopup} 
                buttons={
                    (
                        <>
                            <Link to="/blogs" className="button primary">I'd love to!</Link> 
                            <Link className="button default" to="/">Next time</Link> 
                        </>
                    )
                }/>
            }

            <div className="text">
                <div className="title text-center">
                    Contact Us
                </div>
                <div className="form-container d-flex aling-items-start">
                    <div className="contact-info">
                        <div className="info-title text-white text-center">
                            Contact Information
                        </div>
                        <div className="contact-text text-left text-white">
                            Fill up the form and our team will get
                            back to you within 48 business hours. 
                        </div>

                        <div className="info">
                            <div className="item d-flex align-items-center">
                                <div className="icon">
                                    <img src={Call} alt="Call" className="img-fluid" />
                                </div>
                                <div className="text text-white">
                                    +91 9849077294
                                </div>
                            </div>

                            <div className="item d-flex align-items-center">
                                <div className="icon">
                                    <img src={Mail} alt="Email" className="img-fluid" />
                                </div>
                                <div className="text text-white">
                                    info@flaplive.com
                                </div>
                            </div>
                            <div className="item d-flex align-items-start">
                                <div className="icon">
                                    <img src={Location} alt="Location" className="img-fluid" />
                                </div>
                                <div className="text text-white">
                                    FLAPLIVE INNOVATIONS PRIVATE LIMITED
                                    3-4-112/6/1, 1st Floor,, Hyderguda Attapur,, HYDERABAD, Hyderabad, Telangana, India, 500048
                                </div>
                            </div>

                            <div className="big-ball">
                                <div className="small-ball"></div>
                            </div>
                        </div>

                    </div>

                    <form className="form" id="ContactUsForm" onSubmit={e => submit(e)}>
                        <div className="form-row d-flex">
                            <div className="form-column">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text" id="first_name" name="first_name" className="form-control" required />
                            </div>

                            <div className="form-column">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text" id="last_name" name="last_name" className="form-control" required />
                            </div>
                        </div>

                        <div className="form-row d-flex">
                            <div className="form-column">
                                <label htmlFor="email">Mail</label>
                                <input type="text" id="email" name="email" className="form-control" required />
                            </div>

                            <div className="form-column">
                                <label htmlFor="phone">Phone</label>
                                <input type="text" id="phone" name="phone" className="form-control" required />
                            </div>
                        </div>

                        <div className="subtitle">
                            Who are You?
                        </div>

                        <div className="form-row">
                            <div className="form-column-radio d-flex">
                            {
							    contactusTypes.map(item => (
                                    <label>
                                        <input type="radio" name="type_id" value={item.id}required /> 
                                        <span>{item.type}</span>
                                    </label>
							    ))
						    }
                               
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-column text-area">
                                <label htmlFor="message">Message</label>
                            </div>
                            <textarea className="form-control" name="message" required id="message" cols="30" rows="10"></textarea>
                        </div>

                        <div className="form-row text-right">
                            <button className="confirm text-white text-center d-flex align-items-center justify-content-center">
                                {
                                    showSpinner ? <Spinner /> : "Send Message"                                    
                                }
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Header;