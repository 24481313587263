import React from "react";
import ReactPlayer from 'react-player';

const Video = () => {
    return (
        <div className="video-wrapper">
        <div className="video center">
            <ReactPlayer
                className='Real-player'
                url='https://www.youtube.com/watch?v=K1eQLe7JSRw'
                controls={true}
                width='100%'
                height='100%'
            />
        </div>
       
    </div>
      
    )
}

export default Video;