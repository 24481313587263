import React from 'react';
import MainLayout from "./../layouts/Main";
import Header from "./../components/about-page/Header";
import Missions from "./../components/about-page/Missions";
import BecomeFlapper from "./../components/about-page/BecomeFlapper";
import Services from "./../components/about-page/Services";
import Investors from "./../components/about-page/Investors";

const AboutPage = () => {
    return (
        <MainLayout nav={<Header />}>
            <div className="about-page" id="AboutPage">
                <Missions />
                <BecomeFlapper />
                <Services />
                <Investors />
            </div>
        </MainLayout>
    )
}

export default AboutPage;