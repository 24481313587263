import React from "react";
import closeIconImage from "./../images/download-app-popup/cross bar.svg"
import GooglePlay from "./../images/download-app-popup/play store button.png";
import mobile from "./../images/download-app-popup/mobile phone.svg";
import  {PLAY_STORE_LINK} from '../constants/index';

/**
 * @param {void} setPoup It's being provided from Navbar Component.
 * @returns Component
 */
const DownloadAppPopup = ({setPopup}) => {
    return (
        <div id="DownloadAppPopup" className="d-flex align-items-center justify-content-center">
            <div className="bg" onClick={() => setPopup(false)}></div>
            <div className="popup-container d-flex">
                <div className="close-icon">
                    <img src={closeIconImage} alt="Close" onClick={() => setPopup(false)}/>
                </div>
                <div className="central w-100">
                    <div className="upper-container d-flex align-items-center justify-content-center">
                        <div className="phone">
                            <img src={mobile} className="img-fluid" alt="Mobile"/>
                        </div>
                        <a href={PLAY_STORE_LINK} target="new_window">
                        <div className="google-play-container">
                            <img src={GooglePlay} alt="Google Play"/>
                            {/* <GooglePlay mode="dark" size="small" /> */}
                        </div>
                        </a>
                    </div>
                    <div className="coming-soon">
                        Coming soon on App store...
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DownloadAppPopup;