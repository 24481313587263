import React from "react";
import WhoWeAreBox from "./WhoWeAreBox";
import Video from "./Video";
import newsReporter from "./../../images/landing-page/news reporter.png";
import footage from "./../../images/landing-page/raw news footage.png";
import score from "./../../images/landing-page/score news credit.png";

import playButton from "./../../images/landing-page/play-button.svg";
import GooglePlay from "./../../global/GooglePlay";


const WhoWeAre = () =>{
    const whoWeAreBoxes = [
        {
            title: (
                <>
                    <span className="blue">Raw News </span>
                    <span className="dark">Footage</span>   
                </>
            ),
            text: (
                <>
                    Get access to <span className="blue">Live & Untouched News</span> from all parts of the world with the help of Flappers.
                </>
            ),
            image: footage,
            icon: playButton
        },
        {
            title: (
                <>
                    <span className="dark">Become a </span>   
                    <span className="blue">Reporter</span>
                </>
            ),
            text: (
                <>
                    <span className="blue">Go live</span> anytime to <span className="blue">Capture Breaking News</span> with or without sharing your credentials.
                </>
            ),
            image: newsReporter,
            icon: playButton
        },
        {
            title: (
                <>
                    <span className="dark">Score </span>   
                    <span className="blue">News Credit</span>
                </>
            ),
            text: (
                <>
                    Make money by recording viral news and <span className="blue">Sell it to News Channels</span> interested in your content.
                </>
            ),
            image: score,
            icon: playButton
        },
    ];
    return (
        <div className="who-we-are container-fluid">
            <div className="intro">
                <div className="title text-center">
                    <span className="black">Who are</span> <span className="blue">We!</span>
                </div>
                <div className="black text">
                    FlapLive is a <span className="blue">Live Video Broadcasting News stage</span> that empowers every individual or 
                    flapper to record and share news transparently all around the world, leaving no scope for news fabrication.
                </div>
            </div>

            <div>
                <div className="boxes">
                    <div className="d-flex justify-content-between">
                        {whoWeAreBoxes.map((box, index) => <WhoWeAreBox key={index} title={box.title} text={box.text} image={box.image} icon={box.icon} />)}
                    </div>
                </div>
            </div>

            <div className="video-container">
                <Video />
            </div>

            <div className="google-play-button center">
                <GooglePlay />
            </div>
        </div>
    )
}


export default WhoWeAre;