import React from "react";

import {Link} from "react-router-dom";

import GooglePlay from "./GooglePlay";
import social1 from "./../images/footer/footer icons.svg"
import social2 from "./../images/footer/footer icons_1.svg";
import social3 from "./../images/footer/footer icons_2.svg";
import social4 from "./../images/footer/footer icons_3.svg";



const Footer = () => {
    const socials = [
        {
            href: "https://www.facebook.com/Flaplive/",
            icon: social2,
            name: "facebook"
        },
        {
            href: "https://twitter.com/Flaplive1#",
            icon: social4,
            name: "twitter"
        },
        {
            href: "https://www.linkedin.com/company/flaplive-global-private-limited",
            icon: social1,
            name: "linkedin"
        },
        {
            href: "https://www.instagram.com/flaplive/?igshid=u2caqwxtbdst",
            icon: social3,
            name: "instagram"
        },
  
    ];

    const nav = [
        {
            title: "Home",
            url: "/"
        },
        {
            title: "About",
            url: "/about"
        },
        {
            title: "Contact Us",
            url: "/contact-us"
        },
        {
            title: "Blog",
            url: "/blogs"
        },
        {
            title: "Terms and Conditions",
            url: "/terms"
        }
    ];

    return (
        <div id="Footer">
            <div className="wrapper">
                <div className="texts text-center">
                    <div className="title">
                        Download FlapLive App
                    </div>
                    <div className="text">
                        The All-New News Platform that is Interactive, Innovative, Irresistible.
                    </div>
                    <div className="google-play-container center">
                        <GooglePlay />                    
                    </div>
                </div>

                <div className="social-media text-right">
                    {
                        socials.map((item, index) => <a key={index} href={item.href} ><img src={item.icon} alt={item.name} className={item.name} /></a>)
                    }
                </div>

                <div className="footer-nav d-flex justify-content-between">
                    <div className="copy-right text-left">
                        Flaplive Innovations Pvt Ltd. All Rights Reserved.
                    </div>     
                    <div className="urls">
                        {
                            nav.map((item, index) => <Link to={item.url} key={index}>{item.title}</Link>)
                        }
                    </div>           
                </div>

                <div className="mobile-data d-none">
                    <div className="left">
                        <div className="social-media-mobile">
                            {
                                socials.map((item, index) => <a key={index} href={item.href} ><img src={item.icon} alt={item.name} className={item.name} /></a>)
                            }
                        </div>
                        <div className="copy-right-mobile text-left">
                            Flaplive Innovations Pvt Ltd. All Rights Reserved.
                        </div>   
                    </div>
                    <div className="right">
                        <div className="urls-mobile">
                            {
                                nav.map((item, index) => <Link to={item.url} key={index}>{item.title}</Link>)
                            }
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;