import React from "react";
import lg from "./../../images/small-logo.png";
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <div className="logo text-center">
            <Link to="/"><img src={lg} alt="Logo" /></Link>
        </div>
    )
}

export default Logo;