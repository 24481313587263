import React from "react";
import mobileOne from "./../../images/landing-page/mobile-1.png";
import mobileTwo from "./../../images/landing-page/mobile-2.png";
import mobileThree from "./../../images/landing-page/mobile-3.png";

const FlapLive = () => {

    return (
        <div id="FlapLive">
            <div className="intro">
                <h3 className="title big-title blue text-center">
                    FlapLive
                </h3>
                <div className="text black text-center m-auto">
                    Every person with a smartphone will become a
                    live news reporter.
                </div>
            </div>

            <div className="phones d-flex justify-content-between">
                <div className="mobile-one">
                    <img src={mobileOne} alt="Mobile One"/>
                </div>
                <div className="mobile-two">
                    <img src={mobileTwo} alt="Mobile two"/>
                </div>
                <div className="mobile-three">
                    <img src={mobileThree} alt="Mobile three"/>
                </div>
            </div>
        </div>
    )
}

export default FlapLive;