import React from 'react';
import MainLayout from "./../layouts/Main";
import Header from "./../components/contact-us/Header";
import { connect } from "react-redux";

const ContactUs = ({API})  => {
    return (
        <MainLayout nav={<Header  API={API} />}></MainLayout>
    )
}
const mapStateToProps = (state) => ({ API: state.API })
export default connect(mapStateToProps) (ContactUs);