import React from "react";
import mission from "./../../images/about/mission.png";
import vision from "./../../images/about/vision.png";

const Missions = () => {
    return (
        <div className="missions contianer-fluid">
            <div className="intro">
                <div className="title black text-center">
                    Live News At The Touch Of Your Fingertips
                </div>
            </div>

            <div className="mission-container">
                <div className="mission mission-one d-flex">
                    <div className="image d-flex align-items-end">
                        <img src={mission} alt="Mission" className="img-fluid" />
                    </div>
                    <div className="content">
                        <div className="title text-center">MISSION</div>
                        <div className="text text-left">
                            We aim to democratize the entire news industry. Our sole objective is to eradicate all scopes of news fabrication by being the first to broadcast breaking news from across the globe.
                        </div>
                    </div>
                </div>


                <div className="mission mission-left d-flex">
                    <div className="content">
                        <div className="title text-center">VISION</div>
                        <div className="text text-left">
                            We pledge to create an independent ecosystem with a forward-thinking news generation model. We envision each flapper located in all urban or remote areas capturing every viral content, helping us become the first to broadcast live news.
                        </div>
                    </div>
                    <div className="image center d-flex align-items-end">
                        <img src={vision} alt="Mission" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Missions;