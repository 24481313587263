import React from 'react';
import Navbar from "./../global/Navbar";
import Footer from "./../global/Footer";

const Main = ({children, nav, foot}) => {
    return (
        <div id="mainLayout" className="layout">
            <Navbar nav={nav} />
                <div id="content">
                    {children}
                </div>
            <Footer foot={foot} />
        </div>
    )
}

export default Main;