
import React, {useState, useEffect} from "react";
import dummyImage from "./../../images/blogs/image.jpg"
import {prettyDate} from "./../../global/helpers";
import SocialShare from "./SocialShare";
import axios from "axios";

const Header = ({id, API}) => {
    const [blog, setBlog] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	
	const getBlog = () => {
		axios.get(API.base + API.blog.replace("{{BLOG_ID}}", id)).then(r => {
			if(r.data.status === "error"){
				setErrorMessage(r.data.errorMessage);
				throw new Error(r.data.errorMessage)
			}
			setBlog(r.data.blog);
		}).catch(e => {
			console.log(e);
		});
    }


    useEffect(() => {
        getBlog();
    }, []);

	const imageURL= API.imageBaseURL + API.blogImage;
    return (
		<>
			<div className="blog-page-title d-none">
				{blog && <div className="title-content" dangerouslySetInnerHTML={{__html: blog.title}}></div>}
			</div>
			<div className="container-fluid blog-page" id="BlogPages">
				<div className="text">
					<div className="data-container text-center">
						{
							blog && (
								<>
									<div className="title" dangerouslySetInnerHTML={{__html: blog.title}}></div>
									<div className="img">
										<img src={blog.coverImage ? imageURL+blog.coverImage : dummyImage} alt={blog.title} className="img-fluid" />
									</div>
									<div className="content" dangerouslySetInnerHTML={{__html: blog.article}}></div>
									<div className="author">
										<span className="posted">Posted By: </span>
										<span className="name">{blog.author}</span>
									</div>
									<div className="date">
										Date: {prettyDate(blog.created_at)}
									</div>
									<SocialShare url={window.location.href} />
								</>
							)
						}

						{
							errorMessage && (<div className="alert alert-danger">{errorMessage}</div>)
						}

					</div>
				</div>
			</div>
		</>
    )
}

export default Header;