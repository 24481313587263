import React from 'react';
import MainLayout from "./../layouts/Main";
import Header from "./../components/landing-page/Header";
import WhoWeAre from "./../components/landing-page/WhoWeAre";
import FlapLive from "./../components/landing-page/FlapLive";
import News from "./../components/landing-page/News";
import Features from "./../components/landing-page/Features";

const LandingPage = () => {
    return (
        <MainLayout nav={<Header />}>
            <div className="landing-page" id="LandingPage">
                <WhoWeAre />
                <FlapLive />
                <News />
                <Features />
            </div>
        </MainLayout>
    )
}

export default LandingPage;