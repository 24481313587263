import React from "react";

import {connect} from "react-redux";

const Investors = ({emails}) => {
    return (
        <div className="container-fluid">
            <div className="investors-contact text-center m-auto">
                <div className="investors">
                    <div className="title">Investors Welcome</div>
                    <div className="text">
                        Drop your interest and email ID . we will get in touch with your
                        <a href={`mailto://${emails.investors}`}>{emails.investors}</a>
                    </div>
                </div>
                <div className="contact">
                    <div className="title">
                        Contact Us
                    </div>
                    <div className="text">
                        <a href={`mailto://${emails.contact}`}>{emails.contact}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ emails: state.emails })

export default connect(mapStateToProps)(Investors);