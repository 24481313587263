import {useEffect} from "react";
import {withRouter} from "react-router-dom";

const ScrollTop = ({history}) => {
    useEffect(() => {
        const listener = history.listen(() => {
            window.scrollTo(0,0);
        });

        return () => {
            listener();
        }
    });

    return null;
}


export default withRouter(ScrollTop);