import { createStore } from "redux";
import reducers from "./reducers";
import  {BLOG_APIS, CONTACTUS_APIS, IMAGE_BASE_URL} from '../constants/index'

const state = {
  login: false,
  auth: false,
  API: {
    imageBaseURL: IMAGE_BASE_URL,
    base: BLOG_APIS.base,
    blogs: BLOG_APIS.blogs,
    blog: BLOG_APIS.blog,
    blogImage: BLOG_APIS.image,
    contact_us_base: CONTACTUS_APIS.base,
    contact_us_insert: CONTACTUS_APIS.contact_us_insert,
    contactusTypes: CONTACTUS_APIS.contact_us_get_types
  },
  emails: {
    investors: "investor@flaplive.com",
    contact: "info@flaplive.com",
  },
};

function configureStore() {
  return createStore(reducers, state);
}

export default configureStore;
