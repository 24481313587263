import React from 'react';
import MainLayout from "./../layouts/Main";
import Header from "./../components/blog/Header";
import { connect } from "react-redux";

const BlogPage = ({match, API}) => {
    const id = match.params.id;
    return (
        <MainLayout nav={<Header id={id} API={API} />}></MainLayout>
    )
}

const mapStateToProps = (state) => ({ API: state.API })

export default connect(mapStateToProps)(BlogPage);