import React from "react";

const Header = () => {
    return (
        <div className="about-page-header container-fluid">
            <div className="text">
                <div className="title text-center">
                    “Experience <span className="blue">Real News;</span> <br/>
                    Anytime, Anywhere.”
                </div>
                <div className="text-box text-black text-left">
                    FlapLive Is a Live Video Broadcasting News Stage that empowers users around the world to get breaking news at their fingertips. We expect to convey the first reachable breaking news crosswise over the globe. Each user is a flapper who will broadcast live news to the Flap Network prior to any news channel, organizations, or newspaper pretense the news.
                </div>
            </div>
        </div>
    )
}

export default Header;