import React from "react";
import contactUsImage from "./../images/contact/contact-us-icon.png"
import closeIconImage from "./../images/contact/close-icon.png"

const Popup = ({text, title, subtitle, buttons, showPopup}) => {
    return (
        <div id="Popup" className="d-flex align-items-center justify-content-center">
            <div className="bg" onClick={() => showPopup(false)}></div>
            <div className="popup-container d-flex">
                <div className="close-icon">
                    <img src={closeIconImage} alt="Close" onClick={() => showPopup(false)}/>
                </div>
                <div className="left-side d-flex align-items-center">
                    <div className="bg-color"></div>
                    <img src={contactUsImage} alt="Contact Us"/>
                </div>
                <div className="central d-flex flex-column justify-content-center align-items-start">
                    <div className="pop-title">{title}</div>
                    <div className="pop-subtitle">{subtitle}</div>
                    <div className="pop-text">{text}</div>
                    <div className="buttons">
                        {buttons}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Popup;