
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import dummyImage from "./../../images/blogs/image.jpg"
import Pagination from "react-js-pagination";
import {ReactComponent as ArrowRight} from "./../../images/blogs/arrow-right.svg";
import {prettyDate} from "./../../global/helpers";
import axios from "axios";

const dummyBlogs = [];

for(let x = 0; x <= 50; x++){
    dummyBlogs.push({
        id: x,
        img: dummyImage,
        title: `Problems Faced by <br /> Current News Media ${x}`,
        author: `Anderson ${x}`,
        published_at: "2020-08-24 15:05:56"
    });
}


const Header = ({API}) => {
    const PER_PAGE = 3;

    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [TOTAL, setTotal] = useState(0);

    /**
     * Update It To Use The API
     */
    const getBlogs = () => {
		axios.get(API.base + API.blogs).then(r => {
			setTotal(r.data.blogs.length);
			setBlogs(r.data.blogs.slice((page - 1) * PER_PAGE, PER_PAGE * page));
		}).catch(e => {
			console.log(e);
		});
    }

	const handlePageChange = (data) => {
		setPage(data);
	}

    useEffect(() => {
        getBlogs();
    }, [page]);
	const imageURL= API.imageBaseURL + API.blogImage;
    return (
        <div className="container-fluid" id="BlogPages">
            <div className="text">
                <div className="title text-center">
                   Blog
                </div>
                <div className="data-container">
					<div className="small-ball"></div>
					<div className="big-ball"></div>
					<div className="items-container d-flex aling-items-start justify-content-center">
						{
							blogs.map(item => (
								<div className="item text-center" key={item.id}>
									<div className="img">
										<img src={item.coverImage ? imageURL+item.coverImage : dummyImage} alt={item.title} />
									</div>
									<div className="content">
										<h4 className="title m-auto" dangerouslySetInnerHTML={{__html: item.title}}></h4>
										<div className="line"></div>
										<div className="author">
											<span className="posted">Posted By: </span>
											<span className="name">{item.author}</span>
										</div>
										<div className="date">
											{prettyDate(item.published_at)}
										</div>
									</div>
									<div className="readmore">
										<Link to={`/blog/${item.id}`}>Read More</Link>
									</div>
								</div>
							))
						}
					</div>

					<div className="pagination-container d-flex center">
						<Pagination
							activePage={page}
							itemsCountPerPage={PER_PAGE}
							totalItemsCount={TOTAL}
							pageRangeDisplayed={5}
							prevPageText={<><ArrowRight /> Prev</>}
							nextPageText={<>Next <ArrowRight /></>}
							firstPageText={null}
							lastPageText={null}
							itemClass={"page"}
							itemClassFirst={"d-none"}
							itemClassLast={"d-none"}
							itemClassPrev={"prev"}
							itemClassNext={"next"}
							onChange={handlePageChange}
						/>
					</div>
                </div>
            </div>
        </div>
    )
}

export default Header;