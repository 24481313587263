import React from "react";


const WhoWeAreBox = ({title, text, image, icon}) => {
    return (
        <div className="box-container">
            <div className="icon">
                <img src={icon} className="img-fluid" alt={title}/>
            </div>
            <div className="img">
                <img src={image} className="img-fluid" alt={title}/>
            </div>
            <div className="title">
                {title}
            </div>
            <div className="text">
                {text}
            </div>
        </div>
    )
}

export default WhoWeAreBox;