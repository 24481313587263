import React from 'react';
import MainLayout from "./../layouts/Main";
import Header from "./../components/blogs/Header";
import { connect } from "react-redux";

const BlogPages = ({API}) => {
    return (
        <MainLayout nav={<Header API={API} />}></MainLayout>
    )
}

const mapStateToProps = (state) => ({ API: state.API })

export default connect(mapStateToProps)(BlogPages);