import React from "react";
import MainLayout from "./../layouts/Main";
import Header from "./../components/terms-page/Header";
import terms from "./../components/terms-page/Terms";

const TermsPage = () => {
    return (
        <MainLayout nav={<Header />}>
            <div id="TermsPage">
                <div className="container-fluid">
                    <div className="text-content">
                        {
                            terms.map((item, index) => (
                                <div className="block" key={index}>
                                    {
                                        item.title && <div className={`title ${item.titleLocation ? item.titleLocation: ""}`}>{item.title}</div>
                                    }
                                    {
                                        item.subtitle && <div className={`subtitle ${item.subTitleLocation ? item.subTitleLocation: ""}`}>{item.subtitle}</div>
                                    }
                                    <div className="text">
                                        {
                                            item.blocks.type === "p" && item.blocks.items.map((block, i) => (<p key={i}>{block}</p>))
                                        }

                                        {
                                            item.blocks.type === "ul" && (
                                                <ul>
                                                    {
                                                        item.blocks.items.map((block, i) => (<li key={i}>{block}</li>))
                                                    }
                                                </ul>
                                            )
                                        }

                                        {
                                            item.blocks.type === "ol" && (
                                                <ol>
                                                    {
                                                        item.blocks.items.map((block, i) => (<li key={i}>{block}</li>))
                                                    }
                                                </ol>
                                            )
                                        }


                                        {
                                            item.blocks.type === "mixed-ol" && (
                                                <ol className={`mixed-ol ${item.blocks.class}`}>
                                                    {
                                                        item.blocks.items.map((block, i) => 
                                                            (
                                                                <li key={i}>
                                                                    {block.text}
                                                                    {block.subText &&  <p>{block.subText}</p>}
                                                                    {
                                                                        block.texts && block.texts.map((text, i) => {
                                                                                switch(text.type){
                                                                                    case "strong":
                                                                                        return (<strong>{text.text}</strong>);
                                                                                    case "ol":
                                                                                        return (
                                                                                            <ol>
                                                                                                {
                                                                                                    text.blocks.map((item, i) => (
                                                                                                        <li key={i}>{item.text} 
                                                                                                            {block.subText &&  <p>{block.subText}</p>}
                                                                                                            {
                                                                                                                item.texts && item.texts.map((text, i) => {
                                                                                                                    switch(text.type){
                                                                                                                            case "strong":
                                                                                                                                return (<strong>{text.text}</strong>);
                                                                                                                            default:
                                                                                                                                return (<p key={i}>{text}</p>)
                                                                                                                        }
                                                                                                                    }
                                                                                                                )
                                                                                                            }
                                                                                                        </li>
                                                                                                    ))
                                                                                                }
                                                                                            </ol>
                                                                                        );
                                                                                    default:
                                                                                        return (<p key={i}>{text}</p>)
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                </ol>
                                            )
                                        }


                                        {
                                            item.text && (<p>{item.text}</p>)
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default TermsPage;