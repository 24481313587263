import React from "react";
import {ReactComponent as Twitter} from "./../../images/social-icons/twitter.svg";
import {ReactComponent as Facebook} from "./../../images/social-icons/facebook.svg";
import {ReactComponent as Linkedin} from "./../../images/social-icons/linkedin.svg";
import {ReactComponent as Instagram} from "./../../images/social-icons/instagram.svg";

const SocialShare = ({url}) => {
    url = encodeURIComponent(url);
    const shares = [
        {
            icon: <Twitter />,
            url: `https://twitter.com/intent/tweet?url=${url}`
        },
        {
            icon: <Linkedin />,
            url: `http://www.linkedin.com/shareArticle?mini=true&url=${url}`
        },
        {
            icon: <Facebook />,
            url: `https://www.facebook.com/sharer/sharer.php?u=${url}`
        },
        {
            icon: <Instagram />,
            url: 'https://www.instagram.com'
        },
    ]

    const share = (url) => {
        let winWidth = 520;
        let winHeight = 350;
        let winTop = (window.screen.height / 2) - (winHeight / 2);
        let winLeft = (window.screen.width / 2) - (winWidth / 2);
        window.open(url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight);
    }

    return (
        <ul className="social-share-list list-unstyled d-flex m-auto align-items-center justify-content-center" id="SocialShare">
            {
                shares.map(item => (
                <li key={item.url}>
                    <a href={item.url} target={`${item.url ? "_blank": ""}`} onClick={() => share(item.url)}>
                        {item.icon}
                    </a>
                </li>))
            }
        </ul>
    )
}

export default SocialShare;