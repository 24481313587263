import React from "react";
import {Link} from "react-router-dom"

const BecomeFlapper = () => {
    return (
        <div className="become-flapper text-center">
            <div className="title">Become A Flapper Today</div>
            <Link to="register" className="register center">
                <span>REGISTER NOW</span>
            </Link>
        </div>
    )
}

export default BecomeFlapper;