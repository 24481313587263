import React , { useState }from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AboutPage from "./pages/AboutPage";
import TermsPage from "./pages/TermsPage";
import LoginPage from "./pages/LoginPage";
import ContactUs from "./pages/ContactUs";
import BlogPages from "./pages/BlogPages";
import BlogPage from "./pages/BlogPage";
import { connect } from "react-redux";
import {login} from "./store/actions";

const Content = ({ auth }) => {
    console.log(auth);
    return (
        <Switch>
            <Route exact path="/" component={LandingPage}/>
            <Route exact path="/about" component={AboutPage}/>
            <Route exact path="/terms" component={TermsPage}/>
            <Route exact path="/contact-us" component={ContactUs}/>
            <Route exact path="/blogs" component={BlogPages}/>
            <Route exact path="/blog/:id" component={BlogPage}/>
            <Route exact path="/login" component={LoginPage}/>
        </Switch>
    )
};
    
const mapStateToProps = (state) => ({ auth: state.auth })

const mapDispatchToProps = (dispatch) => {
    return {
      loginAction: (payload) => dispatch(login(payload)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Content);