import React, {useState} from 'react';
import { NavLink  } from "react-router-dom";
import logo from "./../images/logo.png";
import mobileLogo from "./../images/logo-mobile.svg";
import mobileLogoText from "./../images/logo-mobile-text.svg";
import HeaderBG from "./../images/header.png";
import DownloadAppPopup from "./../global/DownloadAppPopup";

const Navbar = ({nav}) => {
    const [showDownloadAppPopup, setShowDownloadAppPopup] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const pages = [
        {
            title: "Home",
            path: "/",
        },
        {
            title: "About",
            path: "/about"
        }
    ]


    const actions = [
        {
            title: "Contact Us",
            type: "link",
            path: "/contact-us"
        },
        {
            title: "Blog",
            type: "link",
            path: "/blogs"
        },
        {
            title: "Download App",
            path: null,
            onClick: (e) => {
                setShowDownloadAppPopup(true);
            }
        }
        ,{
            title: "Client Login",
            path: "/login"
        }
    ]

    const mobileInfo = {
        links: () => {
            let links = [...pages];
            actions.map(item => {
                if(item.type === "link"){
                    links.push(item);
                }
                return item;
            })

            return links;
        },
        buttons: () => {
            let links = [];
            actions.map(item => {
                if(!item.type){
                    links.push(item);
                }
                return item;
            })
            return links;
        }
    };

    return (
        <nav id="navbar">
            {showDownloadAppPopup && <DownloadAppPopup setPopup={setShowDownloadAppPopup} />}
            <div className="d-flex align-items-center links-container justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="logo">
                        <img src={logo} alt="Logo" className="img-fluid" />
                    </div>
                    <div className="links">
                        <ul className="list-unstyled">
                            {
                                pages.map(page => (<li key={page.path}><NavLink exact={true} to={page.path}><span>{page.title}</span></NavLink ></li>))
                            }
                        </ul>
                    </div>
                </div>

                <div className="actions">
                    <ul className="list-unstyled">
                        {
                            actions.map(action => (
                                    <li key={action.path} className={action.type == "link" ? "link-container": 'action-container'}>
                                        {
                                            action.path ? (
                                                <NavLink exact={true} to={action.path}><span>{action.title}</span> </NavLink>
                                            ) : (
                                                <a href="#" onClick={action.onClick ? action.onClick : () => {}}><span>{action.title}</span></a>
                                            )
                                        }
                                        
                                    </li>
                                )
                            )
                        }
                    </ul>
                </div>
            </div>
            
            <div className="align-items-center links-container-mobile justify-content-between d-none">
                <div className="toggle-menu" onClick={() => setShowMenu(!showMenu)}>
                    <div className={`burger-icon ${showMenu ? 'active': ''}`}></div>
                </div>
                <div className="logo d-flex align-items-center">
                    <img src={mobileLogo} alt="Logo" className="img-fluid" />
                    <img src={mobileLogoText} alt="Logo Text" className="img-fluid" />
                </div>
                <div></div>
            </div>
            
            <div className={`popup ${!showMenu ? 'hide': ''}`}>
                <div className="links">
                    <ul className="list-unstyled">
                        {
                            mobileInfo.links().map(page => (<li key={page.path}><NavLink exact={true} to={page.path}><span>{page.title}</span> <div className="line"></div></NavLink ></li>))
                        }
                    </ul>
                </div>

                <div className="buttons d-flex justfiy-content-between align-items-center">
                    {
                        mobileInfo.buttons().map((page, index) => {
                                return page.path ? (
                                    <><NavLink exact={true} to={page.path}><span>{page.title}</span> </NavLink></>
                                ) : (
                                    <><a href="#" className="bg" onClick={page.onClick ? page.onClick : () => {}}><span>{page.title}</span></a> {index === 0 && (<div className="sep"></div>)}</>
                                )
                            }
                        )
      
                    }
                </div>
            </div>

            <div className="custom-nav-content">
                {nav}
            </div>
            <div className="header-bg">
                <img src={HeaderBG} alt="bg-image"/>
            </div>
        </nav>
    )
}

export default Navbar;