import React, {useState} from "react";
import { Link } from "react-router-dom";
import {ReactComponent as Tick} from "./../../images/check.svg"

const Form = ({login}) => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [remember, setRemember] = useState(false);

    const defaultLogin = (e) => {
        e.preventDefault();
        login({
            email, password, remember,
            type: "DEFAULT_LOGIN"
        });
    }

    const handleGoogleLogin = () => {
        alert("Handle Google Login");
    }

    const handleFacebookLogin = () => {
        alert("Handle Facebook Login");
    }


    return (
        <div className="form-data">
            <div className="form-title">Welcome Back!</div>
            <form onSubmit={defaultLogin}>
                <input type="text" required name="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter Your Email" />
                <input type="password" required name="password" onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Enter Your Password" />
                <div className="remember-forget d-flex justify-content-between align-items-center">
                    <label className="remember">
                        <input type="checkbox" name="remember" onChange={(e) => setRemember(e.target.checked)} /> 
                        <span className="icon-container">
                            {remember && <Tick /> }
                        </span>
                        <span className="text">
                            Remember me
                        </span>
                    </label>
                    <div className="forget text-right">
                        <Link to="forget-password">Forgot password?</Link>
                    </div>
                </div>

                <div className="buttons">
                    <button className="login custom-button custom-button-primary">
                        Login
                    </button>

                    <div className="social custom-button custom-button-default google" onClick={handleGoogleLogin}>
                        Login with Google
                    </div>

                    <div className="social custom-button custom-button-default facebook" onClick={handleFacebookLogin}>
                        Login with Facebook
                    </div>
                </div>
            </form>

            <div className="signup">
                <span>Don’t have an account?</span> <Link to="signup">signup</Link>
            </div>

            <div className="terms text-center">
                <Link to="terms">Privacy Policy and Terms of Services</Link>
            </div>
        </div>
    )
}

export default Form;