export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_URL;
export const ITEMS_COUNT_PER_PAGE =  20;
export const BLOG_APIS = {
    base : API_BASE_URL, 
    blogs : '/v1/blog', 
    blog : '/v1/blog/get-blog-details?blog_id={{BLOG_ID}}', 
    image: '/blog/'

};

export const CONTACTUS_APIS = {
    base : API_BASE_URL, 
    contact_us_insert : '/v1/contact-us/insert',
    contact_us_get_types : '/v1/contact-us/get-types'
};

export const PLAY_STORE_LINK= 'https://play.google.com/store/apps/details?id=com.flaplive.news';