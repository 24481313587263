import React from "react";
import servicesIconsBG from "./../../images/about/services-icons-bg.svg";
import goLive from "./../../images/about/Go live.png";
import groupLive from "./../../images/about/group live.png";
import forum from "./../../images/about/forum.png";

import goLiveIconOne from "./../../images/about/go live icons.svg";
import goLiveIconTwo from "./../../images/about/go live icons_1.svg";
import goLiveIconThree from "./../../images/about/go live icons_2.svg";
import goLiveIconFour from "./../../images/about/go live icons_3.svg";
import goLiveIconFive from "./../../images/about/go live icons_4.svg";
import goLiveIconSix from "./../../images/about/go live icons_5.svg";

import groupLiveIconOne from "./../../images/about/group live icons.svg";
import groupLiveIconTwo from "./../../images/about/group live icons_1.svg";
import groupLiveIconThree from "./../../images/about/group live icons_2.svg";

import forumOne from "./../../images/about/forum.svg";
import forumTow from "./../../images/about/forum_1.svg";
import forumThree from "./../../images/about/forum_2.svg";
import forumFour from "./../../images/about/forum_3.svg";

const Services = () => {
    const servicesData = [
        {
            title: "Go live",
            hasbg: true,
            class: "go-live",
            img: goLive,
            left: [
                {
                    icon: goLiveIconSix,
                    text: (
                        <>
                            Stream <br />
                            Live.
                        </>
                    )
                },
                {
                    icon: goLiveIconFive,
                    text: (
                        <>
                            Invite <br />
                            friends.
                        </>
                    )
                },
                {
                    icon: goLiveIconFour,
                    text: (
                        <>
                            Tag live <br/>
                            with Breaking <br/>
                            stream and sell.
                        </>
                    )
                },
            ],
            right: [
                {
                    icon: goLiveIconThree,
                    text: (
                        <>
                            Share on FB, <br />
                            Twitter & IG.
                        </>
                    )
                },
                {
                    icon: goLiveIconTwo,
                    text: (
                        <>
                            SLAP <br />
                            N <br />
                            CLAP
                        </>
                    )
                },
                {
                    icon: goLiveIconOne,
                    text: (
                        <>
                            LIKE <br />
                            DISLIKE
                        </>
                    )
                },
            ]
        },
        {
            title: "Group live",
            hasbg: false,
            class: "group-live",
            img: groupLive,
            left: [
                {
                    icon: goLiveIconFive,
                    text: (
                        <>
                            Invite <br />
                            friends.
                        </>
                    )
                },
                {
                    icon: groupLiveIconOne,
                    text: (
                        <>
                            Host a group <br />
                            discussions.
                        </>
                    )
                },
            ],
            right: [
                {
                    icon: groupLiveIconTwo,
                    text: (
                        <>
                            Share on FB, <br />
                            Twitter & IG.
                        </>
                    )
                },
                {
                    icon: groupLiveIconThree,
                    text: (
                        <>
                            Live video <br />
                            debate with <br />
                            public figures.
                        </>
                    )
                },
            ]
        },
        {
            title: "Forum",
            hasbg: true,
            class: "forum-live",
            img: forum,
            left: [
                {
                    icon: forumFour,
                    text: (
                        <>
                            Watch breaking <br />
                            stream.
                        </>
                    )
                },
                {
                    icon: forumThree,
                    text: (
                        <>
                            Watch hot <br />
                            live.
                        </>
                    )
                },
            ],
            right: [
                {
                    icon: forumTow,
                    text: (
                        <>
                            Watch live <br />
                            discussions.
                        </>
                    )
                },
                {
                    icon: forumOne,
                    text: (
                        <>
                            Watch celeb <br />
                            live.
                        </>
                    )
                },
            ]
        }
    ]

    return (
        <div className="services">
            <div className="title text-center">SERVICES</div>
            {
                servicesData.map((item, index) => (
                    <div className={`${item.class} ${item.hasbg ? "has-bg" : ""} service`} key={index}>
                        <div className="service-title text-center">{item.title}</div>

                        <div className="services-circle center">
                            <div className="circle-image">
                                <img src={servicesIconsBG} alt="Services Icons Background"/>
                                <img src={item.img} alt={item.title} className="inner-image m-auto" />
                            </div>
                            <div className={`service-left d-flex flex-column align-items-end`}>
                                {
                                    item.left.map((left, i) => (
                                        <div className="service-icon center" key={i}>
                                            <div className="icon">
                                                <img src={left.icon} alt="Icon" className="img-fluid" />
                                            </div>
                                            <div className="sep"></div>
                                            <div className="text">
                                                {left.text}
                                            </div>
                                        </div>
                                    )) 
                                }
                            </div>

                            <div className={`service-right d-flex flex-column align-items-start`}>
                                {
                                    item.right.map((right, i) => (
                                        <div className="service-icon center" key={i}>
                                            <div className="icon">
                                                <img src={right.icon} alt="Icon" className="img-fluid" />
                                            </div>
                                            <div className="sep"></div>
                                            <div className="text">
                                                {right.text}
                                            </div>
                                        </div>
                                    )) 
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
            {/* <div className="go-live has-bg service">
                <div className="service-title text-center">Go live</div>

                <div className="services-circle center">
                    <div className="circle-image">
                        <img src={servicesIconsBG} alt="Services Icons Background"/>
                        <img src={goLive} alt="Go Live" className="inner-image m-auto" />
                    </div>
                    <div className="service-left d-flex flex-column align-items-end">
                        <div className="service-icon center">
                            <div className="icon">
                                <img src={goLiveIconOne} alt="Go Live 1" className="img-fluid" />
                            </div>
                            <div className="sep"></div>
                            <div className="text">
                                Tag live <br/>
                                with Breaking <br/>
                                stream and sell.
                            </div>
                        </div>
                        <div className="service-icon center">
                            <div className="icon">
                                <img src={goLiveIconOne} alt="Go Live 1" className="img-fluid" />
                            </div>
                            <div className="sep"></div>
                            <div className="text">
                                Tag live <br/>
                                with Breaking <br/>
                                stream and sell.
                            </div>
                        </div>
                        <div className="service-icon center">
                            <div className="icon">
                                <img src={goLiveIconOne} alt="Go Live 1" className="img-fluid" />
                            </div>
                            <div className="sep"></div>
                            <div className="text">
                                Tag live <br/>
                                with Breaking <br/>
                                stream and sell.
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Services;